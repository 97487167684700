import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { COLORS, TYPOGRAPHY } from '@app/constants';
import { InputState } from '@app/types';
import { CloseCircle, TickCircle } from '@app/ui/iconComponents';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import 'react-phone-number-input/style.css';

export interface PhoneInputProps {
  name: string;
  control: any;
  label?: string;
  textError?: string;
  inputState?: InputState | false;
  maxLength?: number;
  isError?: boolean;
  className?: string;
  mask?: string | Array<string | RegExp>;
  placeholder: string;
  isValid: boolean;
}

const PhoneInput: FC<PhoneInputProps> = ({
  label,
  textError,
  inputState = false,
  isError = false,
  className,
  control,
  name,
  isValid,
  ...rest
}) => {
  return (
    <Root className={className}>
      {label && <Label>{label}</Label>}
      <InputWrap $isError={isError}>
        <StyledPhoneInput
          name={name}
          control={control}
          international
          defaultCountry="RU"
          {...rest}
        />
        <StateWrapper>
          {inputState === InputState.success && isValid && (
            <TickCircle color={COLORS.systemSuccess} />
          )}
          {inputState === InputState.error && !isValid && (
            <CloseCircle color={COLORS.systemError} />
          )}
        </StateWrapper>
      </InputWrap>
      {textError && <TextError>{textError}</TextError>}
    </Root>
  );
};

PhoneInput.displayName = 'PhoneInput';

const Root = styled.div`
  position: relative;
`;

const StateWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 16px;
  svg {
    height: 16px;
    width: 16px;
  }
`;

const Label = styled.label`
  margin-bottom: 4px;
  ${TYPOGRAPHY.footnoteMedium13}
  color: ${COLORS.grayscale600};
  display: block;
  opacity: 0.75;
`;

const InputWrap = styled.div<{ $isError: boolean }>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  .PhoneInputCountry {
    margin-right: 10px;
  }

  ${({ $isError }) =>
    $isError
      ? css`
          .PhoneInput {
            border-color: ${COLORS.systemError};
          }
        `
      : css`
          .PhoneInput {
            &:hover {
              border-color: ${COLORS.grayscale200};
            }
          }
          .PhoneInput--focus {
            border-color: ${COLORS.primary300} !important;
          }
        `};
`;

const StyledPhoneInput = styled(PhoneInputWithCountry)`
  background-color: ${COLORS.grayscaleWhite};
  width: 100%;
  height: 44px;
  padding: 11px 16px;
  ${TYPOGRAPHY.body1Regular14}
  color: ${COLORS.grayscale700};
  border: 1px solid ${COLORS.grayscale100};
  border-radius: 10px;
  outline: none;

  &:disabled {
    opacity: 0.5;
    border-color: ${COLORS.grayscale100};
  }

  &::placeholder {
    color: ${COLORS.grayscale400};
  }

  &:not(:read-only):focus {
    &::placeholder {
      opacity: 0;
    }
  }
`;

const TextError = styled.p`
  ${TYPOGRAPHY.footnoteMedium13}
  color: ${COLORS.systemError};
  margin-top: 4px;
`;

export default PhoneInput;
