// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { COLORS, TYPOGRAPHY } from '@app/constants';
import { BreakpointsEnum } from '@app/types';
import { ButtonHTMLAttributes, cloneElement, FC, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import Spinner from '../Spinner';

type StyleButton = 'primary' | 'secondary' | 'tertiary' | 'text' | 'cancel';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleType?: StyleButton;
  isLoading?: boolean;
  isDisabled?: boolean;
  icon?: ReactElement;
  isReversed?: boolean;
  isLarge?: boolean;
  className?: string;
}

const Button: FC<Props> = ({
  styleType = 'secondary',
  isLoading = false,
  isDisabled = false,
  icon,
  isReversed = false,
  isLarge = false,
  className,
  onClick,
  ...props
}) => {
  const spinnerColor =
    styleType === 'primary'
      ? COLORS.grayscaleWhite
      : styleType === 'cancel'
      ? COLORS.systemError
      : undefined;

  const cloneIcon =
    icon &&
    cloneElement(icon, {
      color: icon.props.color || 'currentColor',
    });

  return (
    <Root
      $type={styleType}
      $isLoading={isLoading}
      disabled={isDisabled}
      $isReversed={isReversed}
      $isLarge={isLarge}
      className={className}
      onClick={(event) => {
        if (onClick && !isLoading) onClick(event);
      }}
      {...props}
    >
      {icon && !isLoading && <Icon>{cloneIcon}</Icon>}
      <span>{props.children}</span>
      {isLoading && (
        <StyledSpinner isLoading={isLoading} color={spinnerColor} />
      )}
    </Root>
  );
};

export default Button;

interface StyleProps {
  $type: StyleButton;
  $isLoading: boolean;
  $isReversed: boolean;
  $isLarge: boolean;
}

const Icon = styled.div`
  display: flex;

  & > svg {
    color: ${COLORS.grayscale700};
  }
`;

const Root = styled.button<StyleProps>`
  ${TYPOGRAPHY.body1Regular14}
  position: relative;
  display: inline-flex;
  flex-direction: ${({ $isReversed }) => $isReversed && 'row-reverse'};
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: ${({ $isLarge }) => ($isLarge ? '12px' : '8px')} 32px;
  min-width: ${({ $isLarge }) => $isLarge && '200px'};

  &:not([disabled]) {
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 3px ${COLORS.elementsStatesFocusedBorder};
    }
  }

  ${Icon} {
    ${({ $isReversed }) =>
      $isReversed
        ? css`
            margin-left: 15px;
          `
        : css`
            margin-right: 15px;
          `};
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    ${Icon} {
      ${({ $isReversed }) =>
        $isReversed
          ? css`
              margin-left: 10px;
            `
          : css`
              margin-right: 10px;
            `};
    }
  }

  ${({ $type, $isLoading }) => {
    switch ($type) {
      case 'primary':
        return css`
          color: ${COLORS.grayscaleWhite};
          background-color: ${COLORS.primary300};

          ${!$isLoading &&
          css`
            &:not([disabled]) {
              &:hover {
                background-color: ${COLORS.primary400};
              }
              &:active {
                background-color: ${COLORS.primary500};

                ${Icon} {
                  & > svg {
                    color: ${COLORS.grayscaleWhite};
                  }
                }
              }
            }
            &[disabled] {
              opacity: 0.55;
            }
          `};
        `;
      case 'secondary':
        return css`
          color: ${COLORS.primary300};
          background-color: ${COLORS.primary50};

          ${!$isLoading &&
          css`
            &:not([disabled]) {
              &:hover {
                background-color: ${COLORS.primary100};
              }
              &:active {
                background-color: ${COLORS.primary200};
              }
            }
            &[disabled] {
              color: ${COLORS.primary200};
            }
          `};
        `;
      case 'cancel':
        return css`
          color: ${COLORS.systemError};
          background-color: ${COLORS.grayscale0};

          ${!$isLoading &&
          css`
            &:not([disabled]) {
              &:hover {
                background-color: ${COLORS.grayscale100};
              }
              &:active {
                background-color: ${COLORS.grayscale200};
              }
            }
            &[disabled] {
              color: ${COLORS.systemErrorLight};
            }
          `};
        `;
      case 'text':
        return css`
          color: ${COLORS.primary300};
          background-color: transparent;

          ${!$isLoading &&
          css`
            &:not([disabled]) {
              &:hover {
                color: ${COLORS.primary400};
              }
              &:active {
                color: ${COLORS.primary500};
              }
            }
            &[disabled] {
              color: ${COLORS.primary200};
            }
          `};

          ${Icon} {
            & > svg {
              color: ${COLORS.primary300};
            }
          }
        `;
      default:
        return css`
          color: ${COLORS.grayscale700};

          border: 1px solid ${COLORS.grayscale100};
          background-color: white;

          ${!$isLoading &&
          css`
            &:not([disabled]) {
              &:hover {
                background-color: ${COLORS.grayscale100};
              }
              &:active {
                background-color: ${COLORS.grayscale200};
              }
            }
            &[disabled] {
              color: ${COLORS.grayscale400};
            }
          `};
        `;
    }
  }};

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      cursor: default;

      & > span {
        opacity: 0;
      }
    `};
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 0;
  left: 0;
`;
