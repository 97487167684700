import { COLORS, TYPOGRAPHY } from '@app/constants';
import { forwardRef, memo } from 'react';
import Countdown, { CountdownTimeDelta, zeroPad } from 'react-countdown';
import styled from 'styled-components';

export interface Props {
  className?: string;
  seconds?: number;
  onComplete?: () => void;
}

const renderer = ({ minutes, seconds, completed }: CountdownTimeDelta) => {
  if (completed) {
    return (
      <span>
        {zeroPad(0)}:{zeroPad(0)}
      </span>
    );
  } else {
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  }
};

const MinutesCountDown = forwardRef<Countdown, Props>(
  ({ className, seconds = 60, onComplete }, ref) => {
    const secondsToMilliseconds = new Date().getTime() + seconds * 1000;

    return (
      <Root className={className}>
        <Countdown
          ref={ref}
          date={secondsToMilliseconds}
          renderer={renderer}
          onComplete={onComplete}
        />
      </Root>
    );
  },
);

MinutesCountDown.displayName = 'MinutesCountDown';

const Root = styled.span`
  ${TYPOGRAPHY.body1Regular14};
  color: ${COLORS.primary300};
`;

export default memo(MinutesCountDown, (prevProps, nextProps) => {
  return prevProps.seconds === nextProps.seconds ? true : false;
});
