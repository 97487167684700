import React, { forwardRef, useId } from 'react';
import styled, { css } from 'styled-components';
import { COLORS, TYPOGRAPHY } from '@app/constants';
import { InputState } from '@app/types';
import { CloseCircle, TickCircle } from '@app/ui/iconComponents';
import ReactInputMask, { Props as ReactInputMaskProps } from 'react-input-mask';

export interface Props extends Omit<ReactInputMaskProps, 'inputRef' | 'mask'> {
  label?: string;
  textError?: string;
  inputState?: InputState | false;
  iconButton?: JSX.Element | false;
  maxLength?: number;
  isError?: boolean;
  className?: string;
  mask?: string | Array<string | RegExp>;
  clickOnIconButton?: () => void;
}

const TextInput = forwardRef<HTMLInputElement | ReactInputMask, Props>(
  (
    {
      label,
      textError,
      inputState = false,
      iconButton = false,
      maxLength,
      type = 'text',
      isError = false,
      className,
      mask,
      maskChar,
      alwaysShowMask,
      readOnly,
      clickOnIconButton,
      ...rest
    },
    ref,
  ) => {
    const ownId = useId();
    const id = rest.id || ownId;

    const getInputMaskProps = () => ({
      mask,
      alwaysShowMask,
      as: ReactInputMask,
      maskChar,
      inputRef: ref,
    });

    return (
      <Root className={className}>
        {label && <Label htmlFor={id}>{label}</Label>}
        <InputWrap>
          <Input
            id={id}
            type={type}
            $isPaddingBig={!!iconButton || !!inputState}
            maxLength={maxLength}
            $isError={isError}
            readOnly={readOnly}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={ref}
            {...(!readOnly && mask && getInputMaskProps())}
            {...rest}
          />
          <StateWrapper>
            {inputState === InputState.success && (
              <TickCircle color={COLORS.systemSuccess} />
            )}
            {inputState === InputState.error && (
              <CloseCircle color={COLORS.systemError} />
            )}
          </StateWrapper>
          {iconButton && (
            <Button type="button" onClick={clickOnIconButton}>
              {iconButton}
            </Button>
          )}
        </InputWrap>
        {textError && <TextError>{textError}</TextError>}
      </Root>
    );
  },
);

TextInput.displayName = 'TextInput';

const Root = styled.div`
  position: relative;
`;

const StateWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 16px;
  svg {
    height: 16px;
    width: 16px;
  }
`;

const Label = styled.label`
  margin-bottom: 4px;
  ${TYPOGRAPHY.footnoteMedium13}
  color: ${COLORS.grayscale600};
  display: block;
  opacity: 0.75;
`;

const InputWrap = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

const Input = styled.input<{
  $isPaddingBig: boolean;
  $isError: boolean;
}>`
  background-color: ${COLORS.grayscaleWhite};
  width: 100%;
  padding: ${({ $isPaddingBig }) =>
    $isPaddingBig ? '11px 48px 11px 16px' : '11px 16px'};
  ${TYPOGRAPHY.body1Regular14}
  color: ${COLORS.grayscale700};
  border: 1px solid ${COLORS.grayscale100};
  border-radius: 10px;
  outline: none;

  &:disabled {
    opacity: 0.5;
    border-color: ${COLORS.grayscale100};
  }

  &::placeholder {
    color: ${COLORS.grayscale400};
  }

  &:not(:read-only):focus {
    &::placeholder {
      opacity: 0;
    }
  }

  ${({ $isError }) =>
    !$isError &&
    css`
      &:hover {
        border-color: ${COLORS.grayscale200};
      }

      &:focus {
        border-color: ${COLORS.primary300};
      }
    `}

  ${({ $isError }) =>
    $isError &&
    css`
      border-color: ${COLORS.systemError};
    `}

  &:read-only {
    border-color: ${COLORS.grayscale100};
    cursor: default;
  }
`;

const TextError = styled.p`
  ${TYPOGRAPHY.footnoteMedium13}
  color: ${COLORS.systemError};
  margin-top: 4px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  right: 16px;
  svg {
    height: 16px;
    width: 16px;
  }
`;

export default TextInput;
