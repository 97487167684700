// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { COLORS, TYPOGRAPHY } from '@app/constants';
import { BreakpointsEnum } from '@app/types';
import CodeInput from '@app/ui/components/CodeInput';
import React, { forwardRef, useRef, useState } from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  length?: number;
  errorMessage?: string;
  onChange: (code: string) => void;
}

const CodeInputField = forwardRef<any, Props>(
  ({ length = 6, errorMessage, className, onChange }, ref) => {
    const [code, setCode] = useState([...Array(length)].map(() => ''));
    const inputsRef = useRef<HTMLInputElement[]>([]);

    const processInput = (
      e: React.ChangeEvent<HTMLInputElement>,
      slot: number,
    ) => {
      const num = e.target.value;

      if (!num) {
        const newCode = [...code];
        newCode[slot] = num;
        setCode(newCode);
        onChange(newCode.join(''));

        if (slot === 0) {
          return;
        }

        inputsRef.current[slot - 1].focus();

        return;
      }

      if (/[^0-9]/.test(num)) return;

      const newCode = [...code];
      newCode[slot] = num;
      setCode(newCode);
      onChange(newCode.join(''));

      if (slot !== length - 1) {
        inputsRef.current[slot + 1].focus();
      }
    };

    const onKeyDown = (e: React.KeyboardEvent, index: number) => {
      if (
        e.key === 'Backspace' &&
        !inputsRef.current[index].value &&
        index > 0
      ) {
        setTimeout(() => {
          inputsRef.current[index - 1].focus();
        }, 0);
      }
    };

    return (
      <Root className={className}>
        <Inputs>
          {code.map((item, index) => {
            return (
              <StyledCodeInput
                key={index}
                inputMode="numeric"
                maxLength={1}
                value={item}
                isSuccess={Boolean(item) && !Boolean(errorMessage)}
                isError={Boolean(errorMessage)}
                autoFocus={!code[0].length && index === 0}
                onChange={(e) => processInput(e, index)}
                onKeyDown={(e) => onKeyDown(e, index)}
                onFocus={(event) => {
                  setTimeout(() => {
                    event.target.setSelectionRange(0, 1);
                  }, 0);
                }}
                ref={(ref: HTMLInputElement) =>
                  (inputsRef.current[index] = ref)
                }
              />
            );
          })}
        </Inputs>
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      </Root>
    );
  },
);

CodeInputField.displayName = 'CodeInputField';

const Root = styled.div`
  position: relative;
`;

const Inputs = styled.div`
  display: flex;
`;

const StyledCodeInput = styled(CodeInput)`
  &:not(:last-child) {
    margin-right: 10px;
  }
  @media (max-width: ${BreakpointsEnum.s}px) {
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
`;

const ErrorText = styled.p`
  ${TYPOGRAPHY.disclaimerRegular12};
  color: ${COLORS.systemError};
  margin-top: 4px;
`;

export default CodeInputField;
