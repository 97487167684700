import { COLORS, TYPOGRAPHY } from '@app/constants';
import FlatBtn from '@app/ui/components/FlatBtn';

import MinutesCountDown, {
  Props as MinutesCountDownProps,
} from '@app/ui/components/MinutesCountDown/MinutesCountDown';
import { FC, useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import styled from 'styled-components';

interface Props
  extends Omit<
    MinutesCountDownProps,
    'className' | 'onChangeIsCompletedCountdown'
  > {
  className?: string;
  text?: string;
  onRepeat?: () => void;
}

const CountdownWithRepeat: FC<Props> = ({
  onRepeat,
  className,
  seconds = 1,
  text = 'Повторная отправка возможна через',
}) => {
  const [isCompletedCountdown, setIsCompletedCountdown] = useState(false);
  const minutesCountDownRef = useRef<Countdown>(null);

  const handleClick = () => {
    onRepeat?.();
  };

  useEffect(() => {
    if (seconds) {
      minutesCountDownRef.current?.start();
      setIsCompletedCountdown(false);
    } else {
      minutesCountDownRef.current?.stop();
      setIsCompletedCountdown(true);
    }
  }, [seconds]);

  return (
    <div className={className}>
      <div>
        <RepeatText>
          {text}{' '}
          <MinutesCountDown
            ref={minutesCountDownRef}
            seconds={seconds}
            onComplete={() => {
              setIsCompletedCountdown(true);
            }}
          />
        </RepeatText>
      </div>
      <RepeatBtn
        styleType="primary"
        onClick={handleClick}
        isDisabled={!isCompletedCountdown}
      >
        Отправить повторно
      </RepeatBtn>
    </div>
  );
};

const RepeatText = styled.p`
  ${TYPOGRAPHY.body1Regular14};
  color: ${COLORS.grayscale600};
  margin-bottom: 10px;
`;

const RepeatBtn = styled(FlatBtn)`
  ${TYPOGRAPHY.body1Medium14}
  padding-left: 0;
  padding-right: 0;
`;

export default CountdownWithRepeat;
