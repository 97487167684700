import { InputState } from '@app/types';

export const getInputState = (
  touchedFields: any,
  errors: any,
  fieldName: string,
) => {
  const isTouched = touchedFields[fieldName] || false;
  const isError = errors[fieldName] || false;

  if (isTouched && isError) {
    return InputState.error;
  }

  if (isTouched && !isError) {
    return InputState.success;
  }

  if (!isTouched && !isError) {
    return false;
  }
};
