import React, { forwardRef, InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '@app/constants';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
  isSuccess?: boolean;
  className?: string;
}

const CodeInput = forwardRef<HTMLInputElement, Props>(
  (
    { isError = false, isSuccess = false, disabled, className, ...rest },
    ref,
  ) => {
    return (
      <Input
        ref={ref}
        $isError={isError}
        $isSuccess={isSuccess}
        disabled={disabled}
        className={className}
        {...rest}
      />
    );
  },
);

CodeInput.displayName = 'CodeInput';

const Input = styled.input<{ $isError: boolean; $isSuccess: boolean }>`
  width: 52px;
  height: 52px;
  padding: 15px 0;
  text-align: center;
  border: 1px solid ${COLORS.grayscale100};
  border-radius: 10px;
  outline: none;

  ${({ $isError }) =>
    !$isError &&
    css`
      &:hover {
        border-color: ${COLORS.grayscale200};
      }

      &:focus {
        border-color: ${COLORS.primary300};
      }
    `}

  ${({ $isError }) =>
    $isError &&
    css`
      border-color: ${COLORS.systemError};
    `}

    ${({ $isSuccess }) =>
    $isSuccess &&
    css`
      border-color: ${COLORS.primary300};
      :hover {
        border-color: ${COLORS.primary300};
      }
    `}

  &:disabled {
    opacity: 0.9;
    border-color: ${COLORS.grayscale100};
  }
`;

export default CodeInput;
