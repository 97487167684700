import { FC, ReactNode, useMemo } from 'react';
import { StepperConsumer, StepperDispatcher } from './context';
import { UseStepperReturn } from './types';

interface Props extends UseStepperReturn {
  children: ReactNode;
}

export const StepperProvider: FC<Props> = ({ children, ...props }) => {
  const { activeComponent, activeStepIndex, ...useStepperDispatcherValue } =
    useMemo(() => props, [props.activeStepIndex]);

  return (
    <StepperConsumer.Provider value={{ activeComponent, activeStepIndex }}>
      <StepperDispatcher.Provider value={useStepperDispatcherValue}>
        {children}
      </StepperDispatcher.Provider>
    </StepperConsumer.Provider>
  );
};
