import { useContext, useMemo, useState } from 'react';
import { StepperConsumer, StepperDispatcher } from './context';
import { UseStepperArguments, UseStepperReturn } from './types';

export const useStepper = ({
  steps,
  defaultStep = 0,
}: UseStepperArguments): UseStepperReturn => {
  const [activeStep, setActiveStep] = useState<number | string>(defaultStep);

  return useMemo(() => {
    const activeStepIndex =
      typeof activeStep === 'number'
        ? activeStep
        : steps.findIndex((item) => item.label === activeStep);

    return {
      switchStep: (label: string) => {
        const stepIndexByLabel = steps.findIndex(
          (item) => item.label === label,
        );
        setActiveStep(stepIndexByLabel);
      },
      nextStep: () => {
        setActiveStep(activeStepIndex + 1);
      },
      previousStep: () => {
        if (activeStepIndex > 0) {
          setActiveStep(activeStepIndex - 1);
        }
      },
      activeStepIndex,
      activeComponent: steps[activeStepIndex].component,
    };
  }, [activeStep]);
};

export const useStepperDispatcher = () => {
  const context = useContext(StepperDispatcher);

  if (!context) {
    throw new Error('put `useStepperDispatcher` hook within Provider');
  }

  return context;
};

export const useStepperConsumer = () => {
  const context = useContext(StepperConsumer);

  if (!context) {
    throw new Error('put `useStepperConsumer` hook within Provider');
  }

  return context;
};
