import React, { forwardRef, InputHTMLAttributes } from 'react';
import { COLORS } from '@app/constants';
import { EyeSlash2, Eye2 } from '@app/ui/iconComponents';
import TextInput from '../TextInput';
import { useToggle } from '@app/hooks';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  textError?: string;
  isError?: boolean;
  className?: string;
  isPasswordVisible?: boolean;
}

const PasswordInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      textError,
      isError,
      className,
      isPasswordVisible = false,
      ...rest
    },
    ref,
  ) => {
    const { state: isVisible, toggle } = useToggle(isPasswordVisible);

    return (
      <TextInput
        ref={ref}
        label={label}
        textError={textError}
        isError={isError}
        className={className}
        type={isVisible ? 'text' : 'password'}
        clickOnIconButton={toggle}
        iconButton={
          isVisible ? (
            <EyeSlash2 color={COLORS.grayscale300} />
          ) : (
            <Eye2 color={COLORS.grayscale300} />
          )
        }
        {...rest}
      />
    );
  },
);

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
